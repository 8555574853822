.Body-Container {
  padding-top: 120px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 20px;
  display: block;
  width: auto;
}

.BackgroundContainer {
  background: rgb(20,20,22);
  border: 1px solid rgb(20,20,22);
  border-radius: 8px;
}

.TextDecorationNone, .TextDecorationNone:hover {
  color: black;
  text-decoration: none;
}